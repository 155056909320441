import React from "react";

const Social = () => {
  return (
    <div className="home__social">
      <a
        href="https://www.instagram.com/tony_pani/"
        className="home__social-icon"
        target="_blank"
      >
        <i className="uil uil-instagram"></i>
      </a>

      <a
        href="https://www.linkedin.com/in/marco-pani/"
        className="home__social-icon"
        target="_blank"
      >
        <i className="uil uil-linkedin-alt"></i>
      </a>

      <a
        href="https://www.youtube.com/channel/UCl-1efk_RSL_9_Rq_zzsRjA/"
        className="home__social-icon"
        target="_blank"
      >
        <i className="uil uil-youtube"></i>
      </a>
    </div>
  );
};

export default Social;
